/* eslint-disable jsx-a11y/iframe-has-title */
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import { io } from 'socket.io-client';
import React, { useMemo, useState } from "react";
import * as Yup from 'yup';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnStrikeThrough,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnUnderline,
  BtnUndo,
  Separator,
  Toolbar,
  Editor,
  EditorProvider,
} from 'react-simple-wysiwyg';

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { lightFormat } from "date-fns";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import PeticaoInicial from "views/admin/default/components/PeticaoInicial";
import { CreatePetition } from "services/peticoes";
import { Field, Form, Formik } from "formik";
import { DeletePetition } from "services/peticoes";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
// import Recurso from "./Recurso";
import { getDownloadRemoteFile } from "utils/functions";
import { EditPetition } from "services/peticoes";
import { GenerateNewParagraph } from "services/chatgpt";
import { MdRefresh } from "react-icons/md";
import { priceUnmask } from "utils/masks";
import { RiArrowGoBackFill } from "react-icons/ri";

export default function ColumnsTable(props) {
    const { columnsData, tableData, getList, totalPages, currentPage, setCurrentPage, id, searchTxt, setSearchTxt, setTabCard } = props;

    const { user } = useAuthContext();
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const tableInstance = useTable(
        {
        columns,
        data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
    } = tableInstance;
    initialState.pageSize = 5;

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const brandStars = useColorModeValue("main.500", "main.400");

    const [isOpen, setIsOpen] = useState(false);
    const [infoRow, setInfoRow] = useState(null);
    const [frame, setFrame] = useState(1);
    const [bodyDocument, setBodyDocument] = useState('');
    const [titleDocument, setTitleDocument] = useState('');
    const [loading, setLoading] = useState(false);

    const onClose = () => {
        setIsOpen(false);
        setFrame(1);
        setInfoRow(null);
        setBodyDocument('');
    };

    const onClickDownload = (item) => {
        getDownloadRemoteFile(item.word);
    };

    const onClickEdit = (item) => {
        setIsOpen(true);
        setFrame(2);
        setInfoRow(item);
        setBodyDocument(item.html);
    };

    const onClickDelete = async (item) => {
        await DeletePetition(item.id, user?.token);
        getList();
    };

    const renderType = () => {
        if (id === 1) {
            return 'Petição inicial';
        }
        if (id === 2) {
            return 'Contestação';
        }
        if (id === 3) {
            return 'Recurso';
        }
        if (id === 4) {
            return 'Contrato';
        }
        if (id === 5) {
            return 'Parecer';
        }
        if (id === 6) {
            return 'Réplica';
        }
        if (id === 7) {
            return 'Petição trabalhista';
        }
    };

    const renderTitle = () => {
        if (frame === 1) {
            return `Criar ${renderType()} - Formulário (1/2)`;
        }
        return `Criar ${renderType()} - Personalizar (2/2)`;
    };

    const renderSubtitleModal = () => {
        if (id === 1) {
            return 'uma petição inicial';
        }
        if (id === 2) {
            return 'uma contestação';
        }
        if (id === 3) {
            return 'um recurso';
        }
        if (id === 4) {
            return 'um contrato';
        }
        if (id === 5) {
            return 'um parecer';
        }
        if (id === 6) {
            return 'uma réplica';
        }
        if (id === 7) {
            return 'uma petição trabalhista';
        }
    };
    
    const renderTitleInput = () => {
        if (id === 1) {
            return 'petição';
        }
        if (id === 2) {
            return 'contestação';
        }
        if (id === 3) {
            return 'recurso';
        }
        if (id === 4) {
            return 'contrato';
        }
        if (id === 5) {
            return 'parecer';
        }
        if (id === 6) {
            return 'réplica';
        }
        if (id === 7) {
            return 'petição trabalhista';
        }
    };

    const renderLabelButton = () => {
        if (id === 1) {
            return 'Nova petição';
        }
        if (id === 2) {
            return 'Nova Contestação';
        }
        if (id === 3) {
            return 'Novo recurso';
        }
        if (id === 4) {
            return 'Novo contrato';
        }
        if (id === 5) {
            return 'Novo parecer';
        }
        if (id === 6) {
            return 'Nova réplica';
        }
        if (id === 7) {
            return 'Nova petição trabalhista';
        }
    };

    const formatType = () => {
        if (id === 1) {
            return 'inicial';
        }
        if (id === 2) {
            return 'contestacao';
        }
        if (id === 3) {
            return 'recurso';
        }
        if (id === 4) {
            return 'contrato';
        }
        if (id === 5) {
            return 'parecer';
        }
        if (id === 6) {
            return 'replica';
        }
        if (id === 7) {
            return 'trabalhistas';
        }
    };

    const renderIdSocket = () => {
      if (id === 7) {
        return 1;
      }
      if (id === 6) {
        return 2;
      }
      return id;
    }

    const actionFrame = async (form, author, reu) => {
        if (frame === 1) {
            setFrame(2);
            setTitleDocument(form.title || '');
            const formatForm = {
              ...form,
              valor: form.valor ? priceUnmask(form.valor) : '',
              id: renderIdSocket(),
              type: id === 7 ? 'trabalhistas' : '',
              idAuthor: author || '',
              idReu: reu || '',
            };
            delete formatForm.provas;
            const socket = io('https://api.advtechpro.tech');
            socket.emit('peticao', formatForm);
            var completeMessage = '';
            socket.on('receberPeticao', function (char) {
                completeMessage += char;
                setBodyDocument(completeMessage);
            });
            return null;
        }
        return onClose();
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().notRequired(),
    });

    const [selection, setSelection] = useState('');

    const handleTextSelection = () => {
      const selectedText = window.getSelection().toString();
      if (selectedText.length > 0) {
        setSelection(`${bodyDocument.replace(selectedText, `§∆†¥λ${selectedText}§∆†¥λ`)}`);
      } else {
        setSelection('');
      }
    };

    const onClickRefreshMessage = async () => {
        setLoading(true);
        const formatBody = selection.split('§∆†¥λ')[1];
        const responseDireito = await GenerateNewParagraph(formatBody);
        setLoading(false);
        const newMessage = responseDireito?.choices[0]?.message?.content;
        if (formatBody === newMessage) {
          setSelection('');
          alert('Não encontramos novas alternativas para o texto.')
          return null;
        }
        if (newMessage) {
            const formatNewMessage = selection.replace(`§∆†¥λ${formatBody}§∆†¥λ`, newMessage);
            setBodyDocument(formatNewMessage);
            setSelection('');
        }
    };

    const renderBody = () => {
        if (frame === 1) {
            return (
                <PeticaoInicial actionFrame={actionFrame} id={id} />
            );
        }
        // if (frame === 1 && id !== 1) {
        //     return (
        //         <Recurso actionFrame={actionFrame} id={id} />
        //     );
        // }
        if (frame === 2) {
            return (
                <Flex
                  w="100%"
                  pt={{ base: '70px', md: '0px' }}
                  direction="column"
                  position="relative"
                >
                    <Formik
                        initialValues={{ title: infoRow?.title || '' }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            try {
                                if (!values.title?.length && id !== 5) {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: 'Preencha o título da descrição' });
                                    helpers.setSubmitting(false);
                                    return null;
                                }
                                const type = formatType();
                                const response = infoRow?.id ? await EditPetition(id === 5 ? titleDocument : values.title, bodyDocument, type, infoRow?.id, user?.token) : await CreatePetition(user?.user?.id, id === 5 ? titleDocument : values.title, bodyDocument, type, user?.token);
                                if (response?.status === 200 || response?.status === 201) {
                                    helpers.setSubmitting(false);
                                    getList();
                                    actionFrame();
                                } else {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: response?.message });
                                    helpers.setSubmitting(false);
                                }
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form>
                                <Grid mb="20px" alignItems="center" templateColumns='repeat(2, 1fr)' gap='16px'>
                                    {id === 5 ? <div /> : (
                                        <Field name='title'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.title && form.touched.title}>
                                                    <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='md'
                                                        fontWeight='500'
                                                        color={textColor}
                                                        mb='8px'
                                                    >
                                                        {`Título da ${renderTitleInput()}`}<Text color={brandStars}>*</Text>
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='md'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder='Digite um título'
                                                        fontWeight='500'
                                                        size='lg'
                                                    />
                                                    <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    )}
                                    <Flex justifyContent="flex-end">
                                        <Button
                                            fontSize='md'
                                            variant='brand'
                                            fontWeight='500'
                                            w='200px'
                                            h='50'
                                            isLoading={props.isSubmitting}
                                            type="submit"
                                        >
                                            Finalizar
                                        </Button>
                                    </Flex>
                                </Grid>
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='md'
                                        fontWeight='400'
                                        marginTop="24px"
                                        mb="24px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                            </Form>
                        )}
                    </Formik>
                    {!selection.length ? null : (
                        <div style={{ top: 'calc(50% - 30px)', right: 20, position: 'fixed', zIndex: 100 }}>
                            <Button
                                fontSize='md'
                                variant='brand'
                                fontWeight='500'
                                w='auto'
                                h='50px'
                                borderRadius="30px"
                                onClick={onClickRefreshMessage}
                                isLoading={loading}
                            >
                                <Icon
                                    as={MdRefresh}
                                    style={{ height: 24, width: 24 }}
                                />
                                <Text
                                  fontSize='md'
                                  fontWeight='500'
                                  ml={1}
                                >
                                  Regenerar texto
                                </Text>
                            </Button>
                        </div>
                    )}
                    <div onMouseUp={handleTextSelection}>
                        <EditorProvider>
                            <Editor style={{ paddingLeft: 20 }} value={bodyDocument} onChange={(e) => setBodyDocument(e.target.value)} containerProps={{ style: { minHeight: 600 } }}>
                                <Toolbar>
                                    <BtnUndo />
                                    <BtnRedo />
                                    <Separator />
                                    <BtnBold />
                                    <BtnItalic />
                                    <BtnUnderline />
                                    <BtnStrikeThrough />
                                    <BtnBulletList />
                                    <BtnNumberedList />
                                    <BtnLink />
                                    <BtnClearFormatting />
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                    </div>
                </Flex>
            );
        }

    }

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex justifyContent="space-between" padding="0px 20px 0px 20px" mb="20px">
            <Flex>
                <Button onClick={() => setTabCard(0)} w='auto' h='44px' mb='10px' variant="brand" alignSelf="flex-end" mr="10px">
                    <Icon as={RiArrowGoBackFill} width='20px' height='20px' color='inherit' />
                </Button>
                <SearchBar
                    placeholder={`Buscar ${renderType()}`}
                    value={searchTxt}
                    setValue={setSearchTxt}
                />
            </Flex>
            <Button onClick={() => setIsOpen(true)} w='200px' h='44px' mb='10px' variant="brand" alignSelf="flex-end" mr="10px" fontSize="lg">
                {renderLabelButton()}
            </Button>
        </Flex>

        <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="full">
            <ModalOverlay />
            <ModalContent style={{ fontFamily: 'Encode Sans Condensed' }}>
                <ModalHeader fontSize="2xl">
                    {renderTitle()}
                    <Text color='secondaryGray.700' fontSize='sm' fontWeight='400'>{`Preencha as informações abaixo para ${infoRow ? 'editar' : 'criar'} ${renderSubtitleModal()}`}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" flexDirection="column">
                    {/* <Button
                        variant='brand'
                        type="submit"
                        alignSelf="flex-end"
                        w="150px"
                        minH="40px"
                        mb="10px"
                        onClick={actionFrame}
                    >
                        {frame === 1 ? 'Próximo passo' : 'Finalizar'}
                    </Button> */}
                    {renderBody()}
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
        <Table {...getTableProps()} variant='simple' color='gray.500' mb="100px">
            <Thead>
            {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                    <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor={borderColor}>
                    <Flex
                        justify='space-between'
                        align='center'
                        style={{ fontFamily: 'Encode Sans Condensed' }}
                        fontSize={{ sm: "12px", lg: "14px" }}
                        color='gray.400'>
                        {column.render("Header")}
                    </Flex>
                    </Th>
                ))}
                </Tr>
            ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
                prepareRow(row);
                return (
                <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "AÇÕES") {
                        return (
                            <Flex align='center' pl="30px" pt="5px">
                                <Menu row={cell.row.original} onClickDownload={cell.row.original.word ? onClickDownload : undefined} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
                            </Flex>
                        )
                    } else if (cell.column.Header === "SITUAÇÃO") {
                        data = (
                        <Text color={cell.value ? 'red.500' : 'green.500'} fontSize="md" fontWeight='700'>
                            {cell.value ? 'Inativo' : 'Ativo'}
                        </Text>
                        );
                    } else if (cell.column.Header === "CRIADO EM") {
                        data = (
                        <Text color={textColor} fontSize="md" fontWeight='700'>
                            {lightFormat(new Date(cell.value), 'dd/MM/yyyy HH:mm')}
                        </Text>
                        );
                    } else if (cell.column.Header === "TÍTULO") {
                        data = (
                        <Text color={textColor} fontSize="md" fontWeight='700'>
                            {cell.value}
                        </Text>
                        );
                    }
                    return (
                        <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize="md"
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                        </Td>
                    );
                    })}
                </Tr>
                );
            })}
            </Tbody>
        </Table>
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
        />
    </Card>
  );
}
