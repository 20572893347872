/* eslint-disable react-hooks/exhaustive-deps */
import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    Icon,
    Input,
    Spinner,
    Stack,
    Tag,
    Text,
    Textarea,
    Tooltip,
    useColorModeValue,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import Dropzone from 'views/admin/profile/components/Dropzone';
import { moneyMask } from 'utils/masks';
import * as Yup from 'yup';
import { getNameWithExtensionFromUrl } from 'utils/masks';
import { useAuthContext } from 'contexts/SidebarContext';
import { useEffect, useRef, useState } from 'react';
import { FaMicrophoneAlt } from 'react-icons/fa';
import { ListCustomers } from 'services/customers';
import { IoSend } from 'react-icons/io5';
import { AnalysisPDF } from 'services/documentService';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

export default function PeticaoInicial({ actionFrame, id }) {
    // eslint-disable-next-line no-unused-vars
    const { user } = useAuthContext();
    const textColor = useColorModeValue("navy.700", "white");
    const bgTextArea = useColorModeValue("white", "transparent");

    const [searchTxtAuthor, setSearchTxtAuthor] = useState('');
    const [allAuthor, setAllAuthor] = useState([]);
    const [searchTxtReu, setSearchTxtReu] = useState('');
    const [allReu, setAllReu] = useState([]);
    const [author, setAuthor] = useState('');
    const [reu, setReu] = useState('');
    const [inputDescription, setInputDescription] = useState('');
    const [inputFundamentos, setInputFundamentos] = useState('');
    const [inputRefuted, setInputRefuted] = useState('');
    const [inputProvas, setInputProvas] = useState('');
    const [refAudio, setRefAudio] = useState('');
    const [loadPdf, setLoadPdf] = useState(false); // TODO (S) - utilizar a flag loadPdf para exibir ou não o loading

    useEffect(() => {
        if (id !== 4) {
            const timeOutId = setTimeout(() => {
                getListAuthor();
            }, 500);
            return () => clearTimeout(timeOutId);
        }
    }, [searchTxtAuthor]);

    const getListAuthor = async () => {
        const response = await ListCustomers(user?.user?.id, null, 'author', searchTxtAuthor, 1, user?.token);
        setAllAuthor(response?.result?.list || []);
    };

    useEffect(() => {
        if (id !== 4) {
            const timeOutId = setTimeout(() => {
                getListReu();
            }, 500);
            return () => clearTimeout(timeOutId);
        }
    }, [searchTxtReu]);

    const getListReu = async () => {
        const response = await ListCustomers(user?.user?.id, null, 'reu', searchTxtReu, 1, user?.token);
        setAllReu(response?.result?.list || []);
    };

    const renderToolTip = (type) => {
        if (type === 'numero' && (id === 2 || id === 3 || id === 6)) {
            if (id === 2 || id === 6) {
                return 'Número do processo a ser contestado';
            }
            return 'Número do processo para recorrer';
        }
        if (type === 'nome') {
            if (id === 1 || id === 4 || id === 5 || id === 7) {
                return 'Nome do cliente que figurará no polo ativo ou passivo, ao qual você está advogando ou advogará.';
            }
            if (id === 2 || id === 6) {
                return 'Identificação do Réu ou Requerido';
            }
            return 'Recorrente ou recorrido';
        }
        if (type === 'valor') {
            if (id === 1 || id === 4 || id === 5 || id === 7) {
                return 'O valor da causa nos termos do art. 292 do CPC';
            }
            if (id === 2 || id === 6) {
                return 'O valor da causa nos termos do art. 292 do CPC';
            }
            return 'O valor da causa nos termos do art. 292 do CPC';
        }
        if (type === 'tipo') {
            if (id === 1 || id === 4 || id === 5 || id === 7) {
                return 'O nome da ação que será redigida com a IA';
            }
            if (id === 2 || id === 6) {
                return 'Informe contestação à ação de [...] (preencha com o nome da ação a ser contestada)';
            }
            return 'Tipo de recurso (deverá ser informado o recurso desejado nos termos do CPC/15 ou CPP)';
        }
        if (type === 'juizo') {
            if (id === 1 || id === 4 || id === 5 || id === 7) {
                return 'Cabeçalho da petição inicial, nos termos do art. 319 I do CPC/15';
            }
            if (id === 2 || id === 6) {
                return 'O Juízo ao qual será dirigida a contestação';
            }
            return 'O Juízo ao qual será dirigido o recurso';
        }
        if (type === 'description') {
            if (id === 1 || id === 4 || id === 5 || id === 7) {
                return 'Descreva os fatos que serão narrados na petição inicial. Apresente todas as informações para sua petição inicial.';
            }
            if (id === 2 || id === 6) {
                return 'Descrição dos pontos a serem contestados, incluindo as preliminares da ação nos termos do art. 337 do CPC.';
            }
            return 'Descrição dos pontos ensejadores da reforma da sentença ou decisão interlocutória.';
        }
        if (type === 'fundamentos') {
            if (id === 1 || id === 4 || id === 5 || id === 7) {
                return 'Informe o fundamento jurídico do pedido (art. 319 III do CPC/15 ou da peça processual penal, trabalhista que você quer que a IA desenvolva). Pressione enter para ir adicionando as teses.';
            }
            if (id === 2 || id === 6) {
                return 'Apresentação das possíveis teses que sustentam sua contestação. Pressione enter para ir adicionando as teses.';
            }
            return 'Apresentação das possíveis teses que reforçam a impugnação da sentença via recurso escolhido. Pressione enter para ir adicionando as teses.';
        }
        if (type === 'provas') {
            if (id === 1 || id === 4 || id === 5 || id === 7) {
                return 'Informe quais provas que você pretende produzir (Art. 319 IV do CPC ou quaisquer outros que sejam relevantes para sua inicial ou documento redigido pela IA).';
            }
            if (id === 2 || id === 6) {
                return 'Mencionar os documentos que serão juntados à contestação.';
            }
            return 'Mencionar os documentos ou informações complementares para impugnação da sentença ou decisão interlocutória.'
        }
    };

    const renderTitleType = () => {
        if (id === 1 || id === 5 || id === 7) {
            return 'Tipo de petição';
        }
        if (id === 2 || id === 6) {
            return 'Tipo de Contestação/Reconvenção/Pedido contraposto';
        }
        if (id === 3) {
            return 'Tipo de recurso';
        }
        if (id === 4) {
            return 'Tipo de contrato';
        }
        return '';
    };

    const onLoadPdf = async (file) => {
        setLoadPdf(true);
        const generateType = () => {
            if (id === 2) {
                return 'contestacao';
            }
            if (id === 3) {
                return 'recurso';
            }
            if (id === 6) {
                return 'replica';
            }
            return null;
        };
        const typeDocument = generateType();
        if (typeDocument) {
            const responseAnalysisPDF = await AnalysisPDF(file[0], generateType(), user?.token);
            const resume = responseAnalysisPDF?.result?.text?.resume;
            const pedidos = responseAnalysisPDF?.result?.text?.pedidos;
            const provas = responseAnalysisPDF?.result?.text?.provas;
            const refuted = responseAnalysisPDF?.result?.text?.refuted;
            setInputDescription(resume);
            setInputFundamentos(pedidos);
            setInputRefuted(refuted);
            setInputProvas(provas);
        }
        setLoadPdf(false);
    };
    
    const validationSchema = Yup.object().shape({
        numero: Yup.string().notRequired(),
        nome: Yup.string().notRequired(),
        // provas: Yup.string().notRequired(),
        valor: Yup.string().notRequired(),
        juizo: Yup.string().notRequired(),
        tipo: Yup.string().notRequired(),
        // fundamentos: Yup.string().notRequired(),
        // description: Yup.string().notRequired(),
        petitionInitial: Yup.array().notRequired(),
    });

    const [isRecording, setIsRecording] = useState(false);
    const recognitionRef = useRef(null);

    const startRecording = () => {
        if (recognitionRef.current) {
            recognitionRef.current.start();
            setIsRecording(true);
        }
    };

    const stopRecording = () => {
        if (recognitionRef.current) {
            recognitionRef.current.stop();
            setIsRecording(false);
        }
    };

    if (!recognitionRef.current && SpeechRecognition) {
        recognitionRef.current = new SpeechRecognition();
        recognitionRef.current.continuous = true;
        recognitionRef.current.interimResults = false;

        recognitionRef.current.onerror = (event) => {
            console.error("Error occurred in recognition: ", event.error);
            setIsRecording(false);
        };

        recognitionRef.current.onend = () => {
            if (isRecording) {
                recognitionRef.current.start();
            } else {
                setIsRecording(false);
            }
        };
    }

    const renderTitleDescription = () => {
        if (id === 2) {
            return 'Síntese da petição inicial';
        }
        if (id === 6) {
          return 'Causas de pedir e pedidos identificados pela IA na contestação';
        }
        if (id === 4) {
            return 'Descrição do contrato';
        }
        if (id === 5) {
            return 'Solicite à IA o parecer desejado';
        }
        if (id === 3) {
          return 'Síntese da Decisão';
        }
        return 'Descrição dos fatos';
    };
    const renderPlaceholderDescription = () => {
        if (id === 2) {
            return 'Depois de realizar o upload da petição inicial, a IA irá apresentar a síntese da petição inicial.';
        }
        if (id === 6) {
          return 'Após o upload do PDF, a IA irá identificar as causas de pedir e os pedidos da contestação';
        }
        if (id === 3) {
          return 'Após o upload da decisão, a IA fará um resumo da decisão';
        }
        return renderTitleDescription();
    };
    const ComponentLoadPdf = () => (
      <Stack sx={{ alignSelf: 'center', alignItems: 'center' }}>
        <Spinner
          thickness='10px'
          speed='0.65s'
          emptyColor='gray.200'
          color="#59ebfd"
          mt={40}
          mb={5}
          style={{ width: 150, height: 150 }}
        />
        <Text
          color="#59ebfd"
          fontSize='md'
          fontWeight='400'
        >
          Estamos analisando seu PDF...
        </Text>
      </Stack>
    );

    const renderPlaceholderTese = () => {
      if (id === 1) {
        return 'Digite os fundamentos jurídicos de forma curta, separando por ponto e vírgula (;) (ex: quando for digitar “indenização por dano moral”, digite apenas indenização dano moral; faça isso para os demais fundamentos.';
      }
      if (id === 7) {
        return 'Digite os fundamentos jurídicos de forma curta, separando por ponto e vírgula (;) (ex: quando for digitar “adicional de hora extra”, digite apenas hora extra; faça isso para os demais fundamentos.';
      }
      if (id === 2) {
        return 'Após o upload do PDF, a IA irá identificar as causas de pedir e os pedidos da petição inicial';
      }
      if (id === 6) {
        return 'Após o upload do PDF, a IA irá identificar as causas de pedir e os pedidos da Contestação.';
      }
      if (id === 3) {
        return 'Após o upload do PDF, a IA irá identificar as causas de pedir e os pedidos para interposição do recurso';
      }
      return 'Digite os fundamentos';
    };

    const renderTese = () => (
      <FormControl mb="16px">
          <Flex alignItems="center" pt="10px" pb="10px">
              <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='md'
                  fontWeight='500'
                  color={textColor}
                  mb='0px'
              >
                  {id === 2 || id === 6 ? 'Causas de pedir e pedidos identificados pela IA' : (id === 3 ? 'Pedidos e causas de pedir sugeridos para impugnação via recurso' : 'Qual fundamento jurídico do pedido você gostaria de desenvolver?')}
              </FormLabel>
              {[2, 3, 6].includes(id) ? null : (
                <Tooltip fontSize="md" label={renderToolTip('fundamentos')}>
                    <InfoOutlineIcon color="gray.500" />
                </Tooltip>
              )}
              {user?.plan?.plan === 'basic' ? null : (
                <Button
                  bg={isRecording && refAudio === 'fundamentos' ? "red.600" : "main.700"}
                  ml="20px"
                  onClick={() => {
                      setRefAudio('fundamentos');
                      if (isRecording) {
                          stopRecording();
                      }
                      if (!isRecording) {
                          startRecording();
                      }

                      recognitionRef.current.onresult = async (event) => {
                          const transcript = event.results[event.resultIndex][0].transcript;
                          const todoOConteudo = transcript.split('');
                          for (let index = 0; index < todoOConteudo.length; index++) {
                              const h = todoOConteudo[index];
                              setInputFundamentos((prevState) => prevState + h);
                              await new Promise((resolve) => setTimeout(() => resolve(true), 10));
                          }
                      };
                  }}
              >
                  <Icon
                      as={isRecording && refAudio === 'fundamentos' ? IoSend : FaMicrophoneAlt}
                      style={{ height: isRecording && refAudio === 'fundamentos' ? 16 : 20, width: isRecording && refAudio === 'fundamentos' ? 16 : 20 }}
                      color="white"
                  />
              </Button>
              )}
          </Flex>
          <Textarea
              variant='auth'
              fontSize='md'
              ms={{ base: "0px", md: "0px" }}
              placeholder={renderPlaceholderTese()}
              fontWeight='500'
              size='lg'
              borderWidth="1px"
              resize="none"
              borderRadius="20px"
              minH="150px"
              bg={bgTextArea}
              css={{
                  '::placeholder': {
                      color: '#A3AED0',
                      fontWeight: 400,
                  }
              }}
              value={inputFundamentos}
              onChange={(e) => setInputFundamentos(e.target.value)}
          />
          <Text
              color="blue.500"
              fontSize='md'
              fontWeight='400'
              marginTop="10px"
          >
            {id === 2 || id === 6 || id === 3 ? 'Importante: Advogado (a), analise cuidadosamente os pontos apresentados pela IA. Supervisione a IA e faça sua verificação.' : ''}
          </Text>
      </FormControl>
    );

  return (
    <Flex
        zIndex='2'
        direction='column'
        w={{ base: "100%", md: "420px" }}
        maxW='100%'
        minW="100%"
        background='transparent'
        borderRadius='15px'
        mx={{ base: "auto", lg: "unset" }}
        me='auto'
        mb={{ base: "20px", md: "auto" }}
    >
        <Formik
            initialValues={{
                title: '',
                numero: '',
                nome: '',
                valor: '',
                tipo: '',
                juizo: '',
                // provas: '',
                // fundamentos: '',
                // description: '',
                petitionInitial: [],
                // numero: '123242',
                // nome: 'Arthur Batista',
                // valor: 'R$ 100,00',
                // tipo: 'Ação do tipo alimentícia',
                // juizo: 'Juízo Trabalhista',
                // provas: `extratos bancários; mensagem de whatsapp`,
                // fundamentos: 'dever do banco proteger o correntista; Cédula de Crédito Bancário;',
                // description: `Descrição dos Fatos

                // O autor, [Nome do Correntista], é um cliente do [Nome do Banco], possuindo uma conta corrente de número [Número da Conta] na referida instituição financeira desde [Data de Abertura da Conta].
                
                // Durante o período de relacionamento bancário, o autor sempre cumpriu com suas obrigações contratuais, mantendo sua conta em bom estado e efetuando os pagamentos devidos de maneira pontual.
                
                // No entanto, o [Nome do Banco] vem negligenciando seu dever de fornecer serviços bancários adequados ao autor. Por exemplo, em [Data do Incidente], o autor tentou efetuar uma transferência eletrônica de fundos para uma conta externa, conforme previsto no contrato de prestação de serviços bancários. Entretanto, a transferência não foi concluída devido a uma falha no sistema do banco.
                
                // Após entrar em contato com o serviço de atendimento ao cliente do [Nome do Banco], o autor foi informado de que a falha no sistema era de responsabilidade da instituição financeira e que a transferência não poderia ser concluída no momento. Essa situação resultou em inconvenientes significativos para o autor, que dependia da transferência para cumprir com seus compromissos financeiros.
                
                // Além disso, o [Nome do Banco] tem sido negligente na prestação de informações claras e precisas sobre as taxas e encargos aplicáveis à conta corrente do autor. Em diversas ocasiões, o autor foi surpreendido por cobranças indevidas e taxas excessivas, sem qualquer explicação ou justificativa por parte do banco.
                
                // Como resultado direto das ações negligentes do [Nome do Banco], o autor sofreu danos financeiros, incluindo perdas decorrentes de taxas bancárias injustificadas, bem como danos morais devido ao estresse e transtornos causados pelas falhas nos serviços bancários.
                
                // Apesar das tentativas do autor de resolver amigavelmente a situação com o [Nome do Banco], a instituição não demonstrou disposição em solucionar os problemas de forma satisfatória. Portanto, o autor se vê obrigado a buscar a intervenção do Poder Judiciário para obter a reparação dos danos sofridos.`,
                // petitionInitial: []
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, helpers) => {
                try {
                    if (id === 4) {
                        actionFrame({
                            "valor": values.valor,
                            "tipo": values.tipo,
                            "description": inputDescription,
                            "id": 4,
                            "contrato": {
                                "contratante": searchTxtAuthor,
                                "contratado": searchTxtReu
                             }
                        }, null, null);
                    } else {
                        actionFrame({
                            ...values,
                            provas: inputProvas,
                            // fundamentos: id === 2 || id === 3 || id === 6 ? inputRefuted : inputFundamentos,
                            fundamentos: inputFundamentos,
                            description: inputDescription,
                        }, author, reu);
                    }
                    helpers.setStatus({ success: false });
                    helpers.setSubmitting(false);
                } catch (err) {
                    helpers.setStatus({ success: false });
                    helpers.setErrors({ submit: err.message });
                    helpers.setSubmitting(false);
                }
            }}
        >
            {(props) => (
                <Form style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                        fontSize='md'
                        variant='brand'
                        alignSelf="flex-end"
                        w="150px"
                        minH="40px"
                        mb="10px"
                        isLoading={props.isSubmitting}
                        type="submit"
                        disabled={loadPdf}
                    >
                        Próximo passo
                    </Button>
                    {props.errors.submit && (
                        <Text
                            color={props.status.success ? 'green.500' : 'red.500'}
                            fontSize='md'
                            fontWeight='400'
                            marginTop="24px"
                            mb="24px"
                            alignSelf="flex-end"
                        >
                            {props.errors.submit}
                        </Text>
                    )}
                    {id !== 5 ? null : (
                        <Field name='title'>
                            {({ field, form }) => (
                                <FormControl isInvalid={form.errors.title && form.touched.title}>
                                    <FormLabel
                                        display='flex'
                                        ms='4px'
                                        fontSize='md'
                                        fontWeight='500'
                                        color={textColor}
                                        mb='8px'
                                    >
                                        Título do parecer
                                    </FormLabel>
                                    <Input
                                        {...field}
                                        variant='auth'
                                        fontSize='md'
                                        ms={{ base: "0px", md: "0px" }}
                                        placeholder='Digite um título'
                                        fontWeight='500'
                                        size='lg'
                                    />
                                    <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>
                    )}
                    {id !== 5 ? null : renderTese()}
                    {id === 1  || id === 4 || id === 5 || id === 7 ? null : (
                        <Field name='petitionInitial'>
                            {({ form }) => (
                                <FormControl alignSelf="center" maxW="50%" mb="24px" isInvalid={form.errors.petitionInitial && form.touched.petitionInitial}>
                                    <Flex display="flex" flexDirection="column" maxW="100%">
                                        <Dropzone
                                            minH="100px"
                                            w="100%"
                                            borderRadius="10px"
                                            mt="24px"
                                            onImageSelect={(file) => {
                                                form.setFieldValue('petitionInitial', file);
                                                if ([2, 3, 6].includes(id)) {
                                                    onLoadPdf(file);
                                                }
                                            }}
                                            limiteSize={7}
                                            accept="application/pdf"
                                            content={
                                                <Box display="flex" flexDirection="column" gap="10px">
                                                    {form?.values?.petitionInitial?.length > 0 ? (
                                                        <Text color='blue.500' fontSize='lg' fontWeight='400'>{getNameWithExtensionFromUrl(String(form?.values?.petitionInitial[0]))}</Text>
                                                    ) : (
                                                        <>
                                                            <Text color='blue.500' fontSize='lg' fontWeight='400'>{`Faça o upload da ${id === 2 ? 'petição inicial' : ( id === 6 ? 'contestação/reconvenção' : 'decisão recorrida')} - atentar para o limite de 7MB`}</Text>
                                                            <Text color='secondaryGray.700' fontSize='lg' fontWeight='400'>Formato aceito: PDF</Text>
                                                        </>
                                                    )}
                                                </Box>
                                            }
                                        />
                                    </Flex>
                                    <FormErrorMessage>{form.errors.petitionInitial}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>
                    )}
                    {loadPdf ? ComponentLoadPdf() : (
                      <>
                        <Grid templateColumns='repeat(2, 1fr)' gap='16px'>
                          {id === 1  || id === 4 || id === 5 || id === 7 ? null : (
                              <Field name='numero'>
                                  {({ field, form }) => (
                                      <FormControl isInvalid={form.errors.numero && form.touched.numero}>
                                          <Flex>
                                              <FormLabel
                                                  display='flex'
                                                  ms='4px'
                                                  fontSize='md'
                                                  fontWeight='500'
                                                  color={textColor}
                                              >
                                                  Número do processo
                                              </FormLabel>
                                              {[2, 3, 6].includes(id) ? null : (
                                                <Tooltip fontSize="md" label={renderToolTip('numero')}>
                                                  <InfoOutlineIcon mt="2px" color="gray.500" />
                                                </Tooltip>
                                              )}
                                          </Flex>
                                          <Input
                                              {...field}
                                              variant='auth'
                                              fontSize='md'
                                              ms={{ base: "0px", md: "0px" }}
                                              placeholder='Número do processo'
                                              fontWeight='500'
                                              size='lg'
                                              inputMode='numeric'
                                              onChange={(event) => {
                                                  let numeroProcesso = event.target.value.replace(/\D/g, '').substring(0, 20);
                                                  let numeroFormatado = '';
                                                  for (let i = 0; i < numeroProcesso.length; i++) {
                                                      if (i === 7) {
                                                          numeroFormatado += '-';
                                                      }
                                                      if (i === 9 || i === 13 || i === 14 || i === 16) {
                                                          numeroFormatado += '.';
                                                      }
                                                      numeroFormatado += numeroProcesso.charAt(i);
                                                  }
                                                  props.handleChange({
                                                      target: {
                                                          name: "numero",
                                                          value: numeroFormatado,
                                                      }
                                                  });
                                              }}
                                          />
                                          <FormErrorMessage mb='24px'>{form.errors.numero}</FormErrorMessage>
                                      </FormControl>
                                  )}
                              </Field>
                          )}
                          {id === 5 || id === 4 ? null : (
                              <Field name='nome'>
                                  {({ field, form }) => (
                                      <FormControl isInvalid={form.errors.nome && form.touched.nome}>
                                          <Flex>
                                              <FormLabel
                                                  display='flex'
                                                  ms='4px'
                                                  fontSize="md"
                                                  fontWeight='500'
                                                  color={textColor}
                                                  mb='8px'
                                              >
                                                  Nome do cliente
                                              </FormLabel>
                                              {[2, 3, 6].includes(id) ? null : (
                                                <Tooltip fontSize="md" label={renderToolTip('nome')}>
                                                  <InfoOutlineIcon mt="2px" color="gray.500" />
                                                </Tooltip>
                                              )}
                                          </Flex>
                                          <Input
                                              {...field}
                                              variant='auth'
                                              fontSize="md"
                                              ms={{ base: "0px", md: "0px" }}
                                              placeholder='Nome do cliente'
                                              fontWeight='500'
                                              size='lg'
                                          />
                                          <FormErrorMessage mb='24px'>{form.errors.nome}</FormErrorMessage>
                                      </FormControl>
                                  )}
                              </Field>
                          )}
                          {id === 5 ? null : (
                              <>
                                  <FormControl>
                                      <FormLabel
                                          display='flex'
                                          ms='4px'
                                          fontSize="md"
                                          fontWeight='500'
                                          color={textColor}
                                          mb='8px'
                                      >
                                          {id === 4 ? 'Contratante' : 'Autor'}
                                      </FormLabel>
                                      <Input
                                          variant='auth'
                                          fontSize="md"
                                          ms={{ base: "0px", md: "0px" }}
                                          placeholder='Buscar autor'
                                          fontWeight='500'
                                          size='lg'
                                          value={searchTxtAuthor}
                                          onChange={(txt) => setSearchTxtAuthor(txt.target.value)}
                                      />
                                      {(id === 4 ? [] : allAuthor).map((v) => (
                                          <Tag
                                              bg={author === v?.id ? 'brand.500' : 'gray'}
                                              color="white"
                                              cursor="pointer"
                                              marginRight="5px"
                                              marginTop="5px"
                                              key={v.id}
                                              onClick={() => setAuthor(v.id)}
                                              fontSize="md"
                                              paddingBottom="5px"
                                              paddingTop="5px"
                                          >
                                              {v.name}
                                          </Tag>
                                      ))}
                                  </FormControl>
                                  <FormControl>
                                      <FormLabel
                                          display='flex'
                                          ms='4px'
                                          fontSize="md"
                                          fontWeight='500'
                                          color={textColor}
                                          mb='8px'
                                      >
                                          {id === 4 ? 'Contratado' : 'Réu'}
                                      </FormLabel>
                                      <Input
                                          variant='auth'
                                          fontSize="md"
                                          ms={{ base: "0px", md: "0px" }}
                                          placeholder='Buscar réu'
                                          fontWeight='500'
                                          size='lg'
                                          value={searchTxtReu}
                                          onChange={(txt) => setSearchTxtReu(txt.target.value)}
                                      />
                                      {(id === 4 ? [] : allReu).map((v) => (
                                          <Tag
                                              bg={reu === v?.id ? 'brand.500' : 'gray'}
                                              color="white"
                                              cursor="pointer"
                                              marginRight="5px"
                                              marginTop="5px"
                                              key={v.id}
                                              onClick={() => setReu(v.id)}
                                              fontSize="md"
                                              paddingBottom="5px"
                                              paddingTop="5px"
                                          >
                                              {v.name}
                                          </Tag>
                                      ))}
                                  </FormControl>
                                  <Field name='valor'>
                                      {({ field, form }) => (
                                          <FormControl isInvalid={form.errors.valor && form.touched.valor}>
                                              <Flex>
                                                  <FormLabel
                                                      display='flex'
                                                      ms='4px'
                                                      fontSize='md'
                                                      fontWeight='500'
                                                      color={textColor}
                                                      mb='8px'
                                                  >
                                                      {id === 4 ? 'Qual o valor do contrato?' : 'Qual o valor da causa?'}
                                                  </FormLabel>
                                                  {[2, 3, 6].includes(id) ? null : (
                                                      <Tooltip fontSize="md" label={renderToolTip('valor')}>
                                                          <InfoOutlineIcon mt="2px" color="gray.500" />
                                                      </Tooltip>
                                                  )}
                                              </Flex>
                                              <Input
                                                  {...field}
                                                  variant='auth'
                                                  fontSize='sm'
                                                  ms={{ base: "0px", md: "0px" }}
                                                  placeholder='R$ 0,00'
                                                  fontWeight='500'
                                                  size='lg'
                                                  onChange={(txt) => {
                                                      const formatMoney = moneyMask(txt.target.value);
                                                      props.handleChange({
                                                          target: {
                                                              name: "valor",
                                                              value: formatMoney,
                                                          }
                                                      });
                                                  }}
                                              />
                                              <FormErrorMessage mb='24px'>{form.errors.valor}</FormErrorMessage>
                                          </FormControl>
                                      )}
                                  </Field>
                                  <Field name='tipo'>
                                      {({ field, form }) => (
                                          <FormControl isInvalid={form.errors.tipo && form.touched.tipo}>
                                              <Flex>
                                                  <FormLabel
                                                      display='flex'
                                                      ms='4px'
                                                      fontSize='md'
                                                      fontWeight='500'
                                                      color={textColor}
                                                      mb='8px'
                                                  >
                                                      {renderTitleType()}
                                                  </FormLabel>
                                                  {[2, 3, 6].includes(id) ? null : (
                                                    <Tooltip fontSize="md" label={renderToolTip('tipo')}>
                                                        <InfoOutlineIcon mt="2px" color="gray.500" />
                                                    </Tooltip>
                                                  )}
                                              </Flex>
                                              <Input
                                                  {...field}
                                                  variant='auth'
                                                  fontSize='md'
                                                  ms={{ base: "0px", md: "0px" }}
                                                  placeholder='Digite o tipo'
                                                  fontWeight='500'
                                                  size='lg'
                                              />
                                              <FormErrorMessage mb='24px'>{form.errors.tipo}</FormErrorMessage>
                                          </FormControl>
                                      )}
                                  </Field>
                                  {id === 4 ? null : (
                                      <Field name='juizo'>
                                          {({ field, form }) => (
                                              <FormControl isInvalid={form.errors.juizo && form.touched.juizo}>
                                                  <Flex>
                                                      <FormLabel
                                                          display='flex'
                                                          ms='4px'
                                                          fontSize='md'
                                                          fontWeight='500'
                                                          color={textColor}
                                                          mb='8px'
                                                      >
                                                          Juízo competente
                                                      </FormLabel>
                                                      {[2, 3, 6].includes(id) ? null : (
                                                        <Tooltip fontSize="md" label={renderToolTip('juizo')}>
                                                            <InfoOutlineIcon mt="2px" color="gray.500" />
                                                        </Tooltip>
                                                      )}
                                                  </Flex>
                                                  <Input
                                                      {...field}
                                                      variant='auth'
                                                      fontSize='md'
                                                      ms={{ base: "0px", md: "0px" }}
                                                      placeholder='Digite o juízo'
                                                      fontWeight='500'
                                                      size='lg'
                                                  />
                                                  <FormErrorMessage mb='24px'>{form.errors.juizo}</FormErrorMessage>
                                              </FormControl>
                                          )}
                                      </Field>
                                  )}
                              </>
                          )}
                        </Grid>
                        <FormControl mt="16px" mb="16px">
                            <Flex alignItems="center" pt="10px" pb="10px">
                                <FormLabel
                                    display='flex'
                                    ms='4px'
                                    fontSize='md'
                                    fontWeight='500'
                                    color={textColor}
                                    mb='0px'
                                >
                                    {renderTitleDescription()}
                                </FormLabel>
                                {[2, 3, 6].includes(id) ? null : (
                                  <Tooltip fontSize="md" label={renderToolTip('description')}>
                                      <InfoOutlineIcon color="gray.500" />
                                  </Tooltip>
                                )}
                                {user?.plan?.plan === 'basic' ? null : (
                                  <Button
                                    bg={isRecording && refAudio === 'description' ? "red.600" : "main.700"}
                                    ml="20px"
                                    onClick={() => {
                                        setRefAudio('description');
                                        if (isRecording) {
                                            stopRecording();
                                        }
                                        if (!isRecording) {
                                            startRecording();
                                        }

                                        recognitionRef.current.onresult = async (event) => {
                                            const transcript = event.results[event.resultIndex][0].transcript;
                                            const todoOConteudo = transcript.split('');
                                            for (let index = 0; index < todoOConteudo.length; index++) {
                                                const h = todoOConteudo[index];
                                                setInputDescription((prevState) => prevState + h);
                                                await new Promise((resolve) => setTimeout(() => resolve(true), 10));
                                            }
                                        };
                                    }}
                                    >
                                    <Icon
                                        as={isRecording && refAudio === 'description' ? IoSend : FaMicrophoneAlt}
                                        style={{ height: isRecording && refAudio === 'description' ? 16 : 20, width: isRecording && refAudio === 'description' ? 16 : 20 }}
                                        color="white"
                                    />
                                  </Button>
                                )}
                            </Flex>
                            <Textarea
                                variant='auth'
                                fontSize='md'
                                ms={{ base: "0px", md: "0px" }}
                                placeholder={renderPlaceholderDescription()}
                                fontWeight='500'
                                size='lg'
                                borderWidth="1px"
                                resize="none"
                                borderRadius="20px"
                                minH="150px"
                                bg={bgTextArea}
                                css={{
                                    '::placeholder': {
                                        color: '#A3AED0',
                                        fontWeight: 400,
                                    }
                                }}
                                value={inputDescription}
                                onChange={(e) => setInputDescription(e.target.value)}
                            />
                            <Text
                                color="blue.500"
                                fontSize='md'
                                fontWeight='400'
                                marginTop="10px"
                            >
                              {id === 3 ? 'Importante: Advogado (a), analise cuidadosamente os pontos apresentados pela IA. Supervisione a IA e faça sua verificação.' : ''}
                            </Text>
                        </FormControl>
                        {id === 4 || id === 5 ? null : renderTese()}
                        {id === 2 || id === 3 || id === 6 ? (
                          <FormControl mb="16px">
                              <Flex alignItems="center" pt="10px" pb="10px">
                                  <FormLabel
                                      display='flex'
                                      ms='4px'
                                      fontSize='md'
                                      fontWeight='500'
                                      color={textColor}
                                      mb='0px'
                                  >
                                      {id === 2 ? 'Causas de pedir e pedidos sugeridos para impugnação' : (id === 6 ? 'Causas de pedir e pedidos sugeridos para impugnação em réplica' : (id === 3 ? 'Causas de pedir e pedidos sugeridos para interposição do recurso' : ''))}
                                  </FormLabel>
                                  {[2, 3, 6].includes(id) ? null : (
                                    <Tooltip fontSize="md" label={renderToolTip('fundamentos')}>
                                        <InfoOutlineIcon color="gray.500" />
                                    </Tooltip>
                                  )}
                                  {user?.plan?.plan === 'basic' ? null : (
                                    <Button
                                      bg={isRecording && refAudio === 'fundamentos' ? "red.600" : "main.700"}
                                      ml="20px"
                                      onClick={() => {
                                          setRefAudio('fundamentos');
                                          if (isRecording) {
                                              stopRecording();
                                          }
                                          if (!isRecording) {
                                              startRecording();
                                          }

                                          recognitionRef.current.onresult = async (event) => {
                                              const transcript = event.results[event.resultIndex][0].transcript;
                                              const todoOConteudo = transcript.split('');
                                              for (let index = 0; index < todoOConteudo.length; index++) {
                                                  const h = todoOConteudo[index];
                                                  setInputRefuted((prevState) => prevState + h);
                                                  await new Promise((resolve) => setTimeout(() => resolve(true), 10));
                                              }
                                          };
                                      }}
                                  >
                                      <Icon
                                          as={isRecording && refAudio === 'fundamentos' ? IoSend : FaMicrophoneAlt}
                                          style={{ height: isRecording && refAudio === 'fundamentos' ? 16 : 20, width: isRecording && refAudio === 'fundamentos' ? 16 : 20 }}
                                          color="white"
                                      />
                                  </Button>
                                  )}
                              </Flex>
                              <Textarea
                                  variant='auth'
                                  fontSize='md'
                                  ms={{ base: "0px", md: "0px" }}
                                  placeholder={id === 6 ? 'Após o upload do PDF, a IA irá identificar as causas de pedir e os pedidos da contestação para impugnação' : (id === 3 ? 'Após o upload do PDF, a IA irá identificar as causas de pedir e os pedidos para interposição do recurso' : 'Após o upload do PDF, a IA irá identificar as causas de pedir e os pedidos da petição inicial')}
                                  fontWeight='500'
                                  size='lg'
                                  borderWidth="1px"
                                  resize="none"
                                  borderRadius="20px"
                                  minH="150px"
                                  bg={bgTextArea}
                                  css={{
                                      '::placeholder': {
                                          color: '#A3AED0',
                                          fontWeight: 400,
                                      }
                                  }}
                                  value={inputRefuted}
                                  onChange={(e) => setInputRefuted(e.target.value)}
                              />
                              <Text
                                  color="blue.500"
                                  fontSize='md'
                                  fontWeight='400'
                                  marginTop="10px"
                              >
                                {id === 2 || id === 6 || id === 3 ? 'Importante: Advogado (a), analise cuidadosamente os pontos apresentados pela IA. Supervisione a IA e faça sua verificação.' : ''}
                              </Text>
                          </FormControl>
                        ) : null}
                        {id === 4 || id === 5 ? null : (
                            <>
                                <FormControl>
                                    <Flex alignItems="center" pt="10px" pb="10px">
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='md'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='0px'
                                        >
                                            Mencione quais provas gostaria de incluir
                                        </FormLabel>
                                        {[2, 3, 6].includes(id) ? null : (
                                          <Tooltip fontSize="md" label={renderToolTip('provas')}>
                                              <InfoOutlineIcon color="gray.500" />
                                          </Tooltip>
                                        )}
                                        {user?.plan?.plan === 'basic' ? null : (
                                          <Button
                                            bg={isRecording && refAudio === 'provas' ? "red.600" : "main.700"}
                                            ml="20px"
                                            onClick={() => {
                                                setRefAudio('provas');
                                                if (isRecording) {
                                                    stopRecording();
                                                }
                                                if (!isRecording) {
                                                    startRecording();
                                                }

                                                recognitionRef.current.onresult = async (event) => {
                                                    const transcript = event.results[event.resultIndex][0].transcript;
                                                    const todoOConteudo = transcript.split('');
                                                    for (let index = 0; index < todoOConteudo.length; index++) {
                                                        const h = todoOConteudo[index];
                                                        setInputProvas((prevState) => prevState + h);
                                                        await new Promise((resolve) => setTimeout(() => resolve(true), 10));
                                                    }
                                                };
                                            }}
                                            >
                                            <Icon
                                                as={isRecording && refAudio === 'provas' ? IoSend : FaMicrophoneAlt}
                                                style={{ height: isRecording && refAudio === 'provas' ? 16 : 20, width: isRecording && refAudio === 'provas' ? 16 : 20 }}
                                                color="white"
                                            />
                                          </Button>
                                        )}
                                    </Flex>
                                    <Textarea
                                        variant='auth'
                                        fontSize='md'
                                        ms={{ base: "0px", md: "0px" }}
                                        placeholder='Digite as provas'
                                        fontWeight='500'
                                        size='lg'
                                        borderWidth="1px"
                                        resize="none"
                                        borderRadius="20px"
                                        minH="150px"
                                        bg={bgTextArea}
                                        css={{
                                            '::placeholder': {
                                                color: '#A3AED0',
                                                fontWeight: 400,
                                            }
                                        }}
                                        value={inputProvas}
                                        onChange={(e) => setInputProvas(e.target.value)}
                                    />
                                </FormControl>
                            </>
                        )}
                        <Button
                            fontSize='md'
                            variant='brand'
                            alignSelf="flex-end"
                            w="150px"
                            minH="40px"
                            mb="16px"
                            mt="16px"
                            isLoading={props.isSubmitting}
                            type="submit"
                        >
                            Próximo passo
                        </Button>
                      </>
                    )}
                </Form>
            )}
        </Formik>
    </Flex>
  );
}
